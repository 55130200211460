import constants from "@src/constants/constants";
import { ID_ICSEARCH } from "@src/constants/server_constants";

export const africaCountries = [
  "AGO",
  "BFA",
  "BDI",
  "BEN",
  "BWA",
  "COD",
  "CAF",
  "COG",
  "CIV",
  "CMR",
  "CPV",
  "ESH",
  "ERI",
  "ETH",
  "GAB",
  "GHA",
  "GMB",
  "GIN",
  "GNQ",
  "GNB",
  "KEN",
  "LBR",
  "LSO",
  "MDG",
  "MLI",
  "MUS",
  "MWI",
  "MOZ",
  "NAM",
  "NER",
  "NGA",
  "RWA",
  "SYC",
  "SSD",
  "SHN",
  "SLE",
  "SEN",
  "STP",
  "SWZ",
  "TCD",
  "TGO",
  "TZA",
  "UGA",
  "MYT",
  "ZAF",
  "ZMB",
  "ZWE",
];

export const asiaPacificCountries = [
  "AFG",
  "ATA",
  "ASM",
  "AUS",
  "BGD",
  "BRN",
  "BTN",
  "CCK",
  "COK",
  "CHN",
  "CXR",
  "FJI",
  "FSM",
  "GUM",
  "HKG",
  "HMD",
  "IDN",
  "IND",
  "IOT",
  "JPN",
  "KGZ",
  "KHM",
  "KIR",
  "PRK",
  "KOR",
  "KAZ",
  "LAO",
  "LKA",
  "MHL",
  "MMR",
  "MNG",
  "MAC",
  "MNP",
  "MDV",
  "MYS",
  "NCL",
  "NFK",
  "NPL",
  "NRU",
  "NIU",
  "NZL",
  "PYF",
  "PNG",
  "PHL",
  "PAK",
  "PCN",
  "PLW",
  "REU",
  "SLB",
  "SGP",
  "SYR",
  "ATF",
  "THA",
  "TJK",
  "TKL",
  "TKM",
  "TON",
  "TLS",
  "TUV",
  "TWN",
  "UMI",
  "UZB",
  "VNM",
  "VUT",
  "WLF",
  "WSM",
];

export const europeCountries = [
  "AND",
  "ALB",
  "ARM",
  "AUT",
  "AZE",
  "BIH",
  "BEL",
  "BGR",
  "BLR",
  "CHE",
  "CYP",
  "CZE",
  "DEU",
  "DNK",
  "EST",
  "ESP",
  "FIN",
  "FRO",
  "FRA",
  "FX",
  "GBR",
  "GEO",
  "GIB",
  "GRL",
  "GRC",
  "HRV",
  "HUN",
  "IRL",
  "ISL",
  "ITA",
  "LIE",
  "LTU",
  "LUX",
  "LVA",
  "MCO",
  "MDA",
  "MKD",
  "MLT",
  "NLD",
  "NOR",
  "POL",
  "PRT",
  "ROU",
  "RUS",
  "SWE",
  "SVN",
  "SJM",
  "SVK",
  "SMR",
  "TUR",
  "UKR",
  "VAT",
  "SRB",
  "MNE",
  "ALA",
  "GGY",
  "IMN",
  "JEY",
];

export const middleEastCountries = [
  "ARE",
  "EGY",
  "ISR",
  "IRQ",
  "IRN",
  "JOR",
  "KWT",
  "LBN",
  "LBY",
  "OMN",
  "QAT",
  "SAU",
  "YEM",
  "BHR",
  "DJI",
  "DZA",
  "COM",
  "MAR",
  "MRT",
  "PSE",
  "SDN",
  "SOM",
  "TUN",
];

export const northAmericaCountries = ["CAN", "SPM", "USA", "NIC"];

export const southLatinAmericaCountries = [
  "ATG",
  "AIA",
  "ANT",
  "ARG",
  "ABW",
  "BRB",
  "BMU",
  "BOL",
  "BRA",
  "BHS",
  "BVT",
  "BLZ",
  "CHL",
  "COL",
  "CRI",
  "CUB",
  "DMA",
  "DOM",
  "ECU",
  "FLK",
  "GRD",
  "GUF",
  "GLP",
  "SGS",
  "GTM",
  "GUY",
  "HND",
  "HTI",
  "JAM",
  "KNA",
  "CYM",
  "LCA",
  "MTQ",
  "MSR",
  "MEX",
  "PAN",
  "PER",
  "PRI",
  "PRY",
  "SUR",
  "SLV",
  "TCA",
  "TTO",
  "URY",
  "VCT",
  "VEN",
  "VGB",
  "VIR",
  "BLM",
  "MAF",
];

export const countriesList = [
  {
    url: `https://${constants.apiHost}/api/countries/AF/`,
    code: "AF",
    iso_3166_1_a3: "AFG",
    printable_name: "Afghanistan",
    iso_3166_1_numeric: "004",
  },
  {
    url: `https://${constants.apiHost}/api/countries/AL/`,
    code: "AL",
    iso_3166_1_a3: "ALB",
    printable_name: "Albania",
    iso_3166_1_numeric: "008",
  },
  {
    url: `https://${constants.apiHost}/api/countries/DZ/`,
    code: "DZ",
    iso_3166_1_a3: "DZA",
    printable_name: "Algeria",
    iso_3166_1_numeric: "012",
  },
  {
    url: `https://${constants.apiHost}/api/countries/AS/`,
    code: "AS",
    iso_3166_1_a3: "ASM",
    printable_name: "American Samoa",
    iso_3166_1_numeric: "016",
  },
  {
    url: `https://${constants.apiHost}/api/countries/AD/`,
    code: "AD",
    iso_3166_1_a3: "AND",
    printable_name: "Andorra",
    iso_3166_1_numeric: "020",
  },
  {
    url: `https://${constants.apiHost}/api/countries/AO/`,
    code: "AO",
    iso_3166_1_a3: "AGO",
    printable_name: "Angola",
    iso_3166_1_numeric: "024",
  },
  {
    url: `https://${constants.apiHost}/api/countries/AI/`,
    code: "AI",
    iso_3166_1_a3: "AIA",
    printable_name: "Anguilla",
    iso_3166_1_numeric: "660",
  },
  {
    url: `https://${constants.apiHost}/api/countries/AQ/`,
    code: "AQ",
    iso_3166_1_a3: "ATA",
    printable_name: "Antarctica",
    iso_3166_1_numeric: "010",
  },
  {
    url: `https://${constants.apiHost}/api/countries/AG/`,
    code: "AG",
    iso_3166_1_a3: "ATG",
    printable_name: "Antigua and Barbuda",
    iso_3166_1_numeric: "028",
  },
  {
    url: `https://${constants.apiHost}/api/countries/AR/`,
    code: "AR",
    iso_3166_1_a3: "ARG",
    printable_name: "Argentina",
    iso_3166_1_numeric: "032",
  },
  {
    url: `https://${constants.apiHost}/api/countries/AM/`,
    code: "AM",
    iso_3166_1_a3: "ARM",
    printable_name: "Armenia",
    iso_3166_1_numeric: "051",
  },
  {
    url: `https://${constants.apiHost}/api/countries/AW/`,
    code: "AW",
    iso_3166_1_a3: "ABW",
    printable_name: "Aruba",
    iso_3166_1_numeric: "533",
  },
  {
    url: `https://${constants.apiHost}/api/countries/AU/`,
    code: "AU",
    iso_3166_1_a3: "AUS",
    printable_name: "Australia",
    iso_3166_1_numeric: "036",
  },
  {
    url: `https://${constants.apiHost}/api/countries/AT/`,
    code: "AT",
    iso_3166_1_a3: "AUT",
    printable_name: "Austria",
    iso_3166_1_numeric: "040",
  },
  {
    url: `https://${constants.apiHost}/api/countries/AZ/`,
    code: "AZ",
    iso_3166_1_a3: "AZE",
    printable_name: "Azerbaijan",
    iso_3166_1_numeric: "031",
  },
  {
    url: `https://${constants.apiHost}/api/countries/BS/`,
    code: "BS",
    iso_3166_1_a3: "BHS",
    printable_name: "The Bahamas",
    iso_3166_1_numeric: "044",
  },
  {
    url: `https://${constants.apiHost}/api/countries/BH/`,
    code: "BH",
    iso_3166_1_a3: "BHR",
    printable_name: "Bahrain",
    iso_3166_1_numeric: "048",
  },
  {
    url: `https://${constants.apiHost}/api/countries/BD/`,
    code: "BD",
    iso_3166_1_a3: "BGD",
    printable_name: "Bangladesh",
    iso_3166_1_numeric: "050",
  },
  {
    url: `https://${constants.apiHost}/api/countries/BB/`,
    code: "BB",
    iso_3166_1_a3: "BRB",
    printable_name: "Barbados",
    iso_3166_1_numeric: "052",
  },
  {
    url: `https://${constants.apiHost}/api/countries/BY/`,
    code: "BY",
    iso_3166_1_a3: "BLR",
    printable_name: "Belarus",
    iso_3166_1_numeric: "112",
  },
  {
    url: `https://${constants.apiHost}/api/countries/BE/`,
    code: "BE",
    iso_3166_1_a3: "BEL",
    printable_name: "Belgium",
    iso_3166_1_numeric: "056",
  },
  {
    url: `https://${constants.apiHost}/api/countries/BZ/`,
    code: "BZ",
    iso_3166_1_a3: "BLZ",
    printable_name: "Belize",
    iso_3166_1_numeric: "084",
  },
  {
    url: `https://${constants.apiHost}/api/countries/BJ/`,
    code: "BJ",
    iso_3166_1_a3: "BEN",
    printable_name: "Benin",
    iso_3166_1_numeric: "204",
  },
  {
    url: `https://${constants.apiHost}/api/countries/BM/`,
    code: "BM",
    iso_3166_1_a3: "BMU",
    printable_name: "Bermuda",
    iso_3166_1_numeric: "060",
  },
  {
    url: `https://${constants.apiHost}/api/countries/BT/`,
    code: "BT",
    iso_3166_1_a3: "BTN",
    printable_name: "Bhutan",
    iso_3166_1_numeric: "064",
  },
  {
    url: `https://${constants.apiHost}/api/countries/BO/`,
    code: "BO",
    iso_3166_1_a3: "BOL",
    printable_name: "Bolivia",
    iso_3166_1_numeric: "068",
  },
  {
    url: `https://${constants.apiHost}/api/countries/BQ/`,
    code: "BQ",
    iso_3166_1_a3: "BES",
    printable_name: "Bonaire, Sint Eustatius and Saba",
    iso_3166_1_numeric: "535",
  },
  {
    url: `https://${constants.apiHost}/api/countries/BA/`,
    code: "BA",
    iso_3166_1_a3: "BIH",
    printable_name: "Bosnia and Herzegovina",
    iso_3166_1_numeric: "070",
  },
  {
    url: `https://${constants.apiHost}/api/countries/BW/`,
    code: "BW",
    iso_3166_1_a3: "BWA",
    printable_name: "Botswana",
    iso_3166_1_numeric: "072",
  },
  {
    url: `https://${constants.apiHost}/api/countries/BV/`,
    code: "BV",
    iso_3166_1_a3: "BVT",
    printable_name: "Bouvet Island",
    iso_3166_1_numeric: "074",
  },
  {
    url: `https://${constants.apiHost}/api/countries/BR/`,
    code: "BR",
    iso_3166_1_a3: "BRA",
    printable_name: "Brazil",
    iso_3166_1_numeric: "076",
  },
  {
    url: `https://${constants.apiHost}/api/countries/IO/`,
    code: "IO",
    iso_3166_1_a3: "IOT",
    printable_name: "British Indian Ocean Territory",
    iso_3166_1_numeric: "086",
  },
  {
    url: `https://${constants.apiHost}/api/countries/BN/`,
    code: "BN",
    iso_3166_1_a3: "BRN",
    printable_name: "Brunei Darussalam",
    iso_3166_1_numeric: "096",
  },
  {
    url: `https://${constants.apiHost}/api/countries/BG/`,
    code: "BG",
    iso_3166_1_a3: "BGR",
    printable_name: "Bulgaria",
    iso_3166_1_numeric: "100",
  },
  {
    url: `https://${constants.apiHost}/api/countries/BF/`,
    code: "BF",
    iso_3166_1_a3: "BFA",
    printable_name: "Burkina Faso",
    iso_3166_1_numeric: "854",
  },
  {
    url: `https://${constants.apiHost}/api/countries/BI/`,
    code: "BI",
    iso_3166_1_a3: "BDI",
    printable_name: "Burundi",
    iso_3166_1_numeric: "108",
  },
  {
    url: `https://${constants.apiHost}/api/countries/CV/`,
    code: "CV",
    iso_3166_1_a3: "CPV",
    printable_name: "Cabo Verde",
    iso_3166_1_numeric: "132",
  },
  {
    url: `https://${constants.apiHost}/api/countries/KH/`,
    code: "KH",
    iso_3166_1_a3: "KHM",
    printable_name: "Cambodia",
    iso_3166_1_numeric: "116",
  },
  {
    url: `https://${constants.apiHost}/api/countries/CM/`,
    code: "CM",
    iso_3166_1_a3: "CMR",
    printable_name: "Cameroon",
    iso_3166_1_numeric: "120",
  },
  {
    url: `https://${constants.apiHost}/api/countries/CA/`,
    code: "CA",
    iso_3166_1_a3: "CAN",
    printable_name: "Canada",
    iso_3166_1_numeric: "124",
  },
  {
    url: `https://${constants.apiHost}/api/countries/KY/`,
    code: "KY",
    iso_3166_1_a3: "CYM",
    printable_name: "Cayman Islands",
    iso_3166_1_numeric: "136",
  },
  {
    url: `https://${constants.apiHost}/api/countries/CF/`,
    code: "CF",
    iso_3166_1_a3: "CAF",
    printable_name: "Central African Republic",
    iso_3166_1_numeric: "140",
  },
  {
    url: `https://${constants.apiHost}/api/countries/TD/`,
    code: "TD",
    iso_3166_1_a3: "TCD",
    printable_name: "Chad",
    iso_3166_1_numeric: "148",
  },
  {
    url: `https://${constants.apiHost}/api/countries/CL/`,
    code: "CL",
    iso_3166_1_a3: "CHL",
    printable_name: "Chile",
    iso_3166_1_numeric: "152",
  },
  {
    url: `https://${constants.apiHost}/api/countries/CN/`,
    code: "CN",
    iso_3166_1_a3: "CHN",
    printable_name: "China",
    iso_3166_1_numeric: "156",
  },
  {
    url: `https://${constants.apiHost}/api/countries/CX/`,
    code: "CX",
    iso_3166_1_a3: "CXR",
    printable_name: "Christmas Island",
    iso_3166_1_numeric: "162",
  },
  {
    url: `https://${constants.apiHost}/api/countries/CC/`,
    code: "CC",
    iso_3166_1_a3: "CCK",
    printable_name: "Cocos (Keeling) Islands",
    iso_3166_1_numeric: "166",
  },
  {
    url: `https://${constants.apiHost}/api/countries/CO/`,
    code: "CO",
    iso_3166_1_a3: "COL",
    printable_name: "Colombia",
    iso_3166_1_numeric: "170",
  },
  {
    url: `https://${constants.apiHost}/api/countries/KM/`,
    code: "KM",
    iso_3166_1_a3: "COM",
    printable_name: "Comoros",
    iso_3166_1_numeric: "174",
  },
  {
    url: `https://${constants.apiHost}/api/countries/CD/`,
    code: "CD",
    iso_3166_1_a3: "COD",
    printable_name: "Democratic Republic of the Congo",
    iso_3166_1_numeric: "180",
  },
  {
    url: `https://${constants.apiHost}/api/countries/CG/`,
    code: "CG",
    iso_3166_1_a3: "COG",
    printable_name: "Republic of the Congo",
    iso_3166_1_numeric: "178",
  },
  {
    url: `https://${constants.apiHost}/api/countries/CK/`,
    code: "CK",
    iso_3166_1_a3: "COK",
    printable_name: "Cook Islands",
    iso_3166_1_numeric: "184",
  },
  {
    url: `https://${constants.apiHost}/api/countries/CR/`,
    code: "CR",
    iso_3166_1_a3: "CRI",
    printable_name: "Costa Rica",
    iso_3166_1_numeric: "188",
  },
  {
    url: `https://${constants.apiHost}/api/countries/HR/`,
    code: "HR",
    iso_3166_1_a3: "HRV",
    printable_name: "Croatia",
    iso_3166_1_numeric: "191",
  },
  {
    url: `https://${constants.apiHost}/api/countries/CU/`,
    code: "CU",
    iso_3166_1_a3: "CUB",
    printable_name: "Cuba",
    iso_3166_1_numeric: "192",
  },
  {
    url: `https://${constants.apiHost}/api/countries/CW/`,
    code: "CW",
    iso_3166_1_a3: "CUW",
    printable_name: "Curaçao",
    iso_3166_1_numeric: "531",
  },
  {
    url: `https://${constants.apiHost}/api/countries/CY/`,
    code: "CY",
    iso_3166_1_a3: "CYP",
    printable_name: "Cyprus",
    iso_3166_1_numeric: "196",
  },
  {
    url: `https://${constants.apiHost}/api/countries/CZ/`,
    code: "CZ",
    iso_3166_1_a3: "CZE",
    printable_name: "Czechia",
    iso_3166_1_numeric: "203",
  },
  {
    url: `https://${constants.apiHost}/api/countries/CI/`,
    code: "CI",
    iso_3166_1_a3: "CIV",
    printable_name: "Côte d'Ivoire",
    iso_3166_1_numeric: "384",
  },
  {
    url: `https://${constants.apiHost}/api/countries/DK/`,
    code: "DK",
    iso_3166_1_a3: "DNK",
    printable_name: "Denmark",
    iso_3166_1_numeric: "208",
  },
  {
    url: `https://${constants.apiHost}/api/countries/DJ/`,
    code: "DJ",
    iso_3166_1_a3: "DJI",
    printable_name: "Djibouti",
    iso_3166_1_numeric: "262",
  },
  {
    url: `https://${constants.apiHost}/api/countries/DM/`,
    code: "DM",
    iso_3166_1_a3: "DMA",
    printable_name: "Dominica",
    iso_3166_1_numeric: "212",
  },
  {
    url: `https://${constants.apiHost}/api/countries/DO/`,
    code: "DO",
    iso_3166_1_a3: "DOM",
    printable_name: "Dominican Republic",
    iso_3166_1_numeric: "214",
  },
  {
    url: `https://${constants.apiHost}/api/countries/EC/`,
    code: "EC",
    iso_3166_1_a3: "ECU",
    printable_name: "Ecuador",
    iso_3166_1_numeric: "218",
  },
  {
    url: `https://${constants.apiHost}/api/countries/EG/`,
    code: "EG",
    iso_3166_1_a3: "EGY",
    printable_name: "Egypt",
    iso_3166_1_numeric: "818",
  },
  {
    url: `https://${constants.apiHost}/api/countries/SV/`,
    code: "SV",
    iso_3166_1_a3: "SLV",
    printable_name: "El Salvador",
    iso_3166_1_numeric: "222",
  },
  {
    url: `https://${constants.apiHost}/api/countries/GQ/`,
    code: "GQ",
    iso_3166_1_a3: "GNQ",
    printable_name: "Equatorial Guinea",
    iso_3166_1_numeric: "226",
  },
  {
    url: `https://${constants.apiHost}/api/countries/ER/`,
    code: "ER",
    iso_3166_1_a3: "ERI",
    printable_name: "Eritrea",
    iso_3166_1_numeric: "232",
  },
  {
    url: `https://${constants.apiHost}/api/countries/EE/`,
    code: "EE",
    iso_3166_1_a3: "EST",
    printable_name: "Estonia",
    iso_3166_1_numeric: "233",
  },
  {
    url: `https://${constants.apiHost}/api/countries/SZ/`,
    code: "SZ",
    iso_3166_1_a3: "SWZ",
    printable_name: "Eswatini",
    iso_3166_1_numeric: "748",
  },
  {
    url: `https://${constants.apiHost}/api/countries/ET/`,
    code: "ET",
    iso_3166_1_a3: "ETH",
    printable_name: "Ethiopia",
    iso_3166_1_numeric: "231",
  },
  {
    url: `https://${constants.apiHost}/api/countries/FK/`,
    code: "FK",
    iso_3166_1_a3: "FLK",
    printable_name: "Falkland Islands",
    iso_3166_1_numeric: "238",
  },
  {
    url: `https://${constants.apiHost}/api/countries/FO/`,
    code: "FO",
    iso_3166_1_a3: "FRO",
    printable_name: "Faroe Islands",
    iso_3166_1_numeric: "234",
  },
  {
    url: `https://${constants.apiHost}/api/countries/FJ/`,
    code: "FJ",
    iso_3166_1_a3: "FJI",
    printable_name: "Fiji",
    iso_3166_1_numeric: "242",
  },
  {
    url: `https://${constants.apiHost}/api/countries/FI/`,
    code: "FI",
    iso_3166_1_a3: "FIN",
    printable_name: "Finland",
    iso_3166_1_numeric: "246",
  },
  {
    url: `https://${constants.apiHost}/api/countries/FR/`,
    code: "FR",
    iso_3166_1_a3: "FRA",
    printable_name: "France",
    iso_3166_1_numeric: "250",
  },
  {
    url: `https://${constants.apiHost}/api/countries/GF/`,
    code: "GF",
    iso_3166_1_a3: "GUF",
    printable_name: "French Guiana",
    iso_3166_1_numeric: "254",
  },
  {
    url: `https://${constants.apiHost}/api/countries/PF/`,
    code: "PF",
    iso_3166_1_a3: "PYF",
    printable_name: "French Polynesia",
    iso_3166_1_numeric: "258",
  },
  {
    url: `https://${constants.apiHost}/api/countries/TF/`,
    code: "TF",
    iso_3166_1_a3: "ATF",
    printable_name: "French Southern Territories",
    iso_3166_1_numeric: "260",
  },
  {
    url: `https://${constants.apiHost}/api/countries/GA/`,
    code: "GA",
    iso_3166_1_a3: "GAB",
    printable_name: "Gabon",
    iso_3166_1_numeric: "266",
  },
  {
    url: `https://${constants.apiHost}/api/countries/GM/`,
    code: "GM",
    iso_3166_1_a3: "GMB",
    printable_name: "Gambia",
    iso_3166_1_numeric: "270",
  },
  {
    url: `https://${constants.apiHost}/api/countries/GE/`,
    code: "GE",
    iso_3166_1_a3: "GEO",
    printable_name: "Georgia",
    iso_3166_1_numeric: "268",
  },
  {
    url: `https://${constants.apiHost}/api/countries/DE/`,
    code: "DE",
    iso_3166_1_a3: "DEU",
    printable_name: "Germany",
    iso_3166_1_numeric: "276",
  },
  {
    url: `https://${constants.apiHost}/api/countries/GH/`,
    code: "GH",
    iso_3166_1_a3: "GHA",
    printable_name: "Ghana",
    iso_3166_1_numeric: "288",
  },
  {
    url: `https://${constants.apiHost}/api/countries/GI/`,
    code: "GI",
    iso_3166_1_a3: "GIB",
    printable_name: "Gibraltar",
    iso_3166_1_numeric: "292",
  },
  {
    url: `https://${constants.apiHost}/api/countries/GR/`,
    code: "GR",
    iso_3166_1_a3: "GRC",
    printable_name: "Greece",
    iso_3166_1_numeric: "300",
  },
  {
    url: `https://${constants.apiHost}/api/countries/GL/`,
    code: "GL",
    iso_3166_1_a3: "GRL",
    printable_name: "Greenland",
    iso_3166_1_numeric: "304",
  },
  {
    url: `https://${constants.apiHost}/api/countries/GD/`,
    code: "GD",
    iso_3166_1_a3: "GRD",
    printable_name: "Grenada",
    iso_3166_1_numeric: "308",
  },
  {
    url: `https://${constants.apiHost}/api/countries/GP/`,
    code: "GP",
    iso_3166_1_a3: "GLP",
    printable_name: "Guadeloupe",
    iso_3166_1_numeric: "312",
  },
  {
    url: `https://${constants.apiHost}/api/countries/GU/`,
    code: "GU",
    iso_3166_1_a3: "GUM",
    printable_name: "Guam",
    iso_3166_1_numeric: "316",
  },
  {
    url: `https://${constants.apiHost}/api/countries/GT/`,
    code: "GT",
    iso_3166_1_a3: "GTM",
    printable_name: "Guatemala",
    iso_3166_1_numeric: "320",
  },
  {
    url: `https://${constants.apiHost}/api/countries/GG/`,
    code: "GG",
    iso_3166_1_a3: "GGY",
    printable_name: "Guernsey",
    iso_3166_1_numeric: "831",
  },
  {
    url: `https://${constants.apiHost}/api/countries/GN/`,
    code: "GN",
    iso_3166_1_a3: "GIN",
    printable_name: "Guinea",
    iso_3166_1_numeric: "324",
  },
  {
    url: `https://${constants.apiHost}/api/countries/GW/`,
    code: "GW",
    iso_3166_1_a3: "GNB",
    printable_name: "Guinea-Bissau",
    iso_3166_1_numeric: "624",
  },
  {
    url: `https://${constants.apiHost}/api/countries/GY/`,
    code: "GY",
    iso_3166_1_a3: "GUY",
    printable_name: "Guyana",
    iso_3166_1_numeric: "328",
  },
  {
    url: `https://${constants.apiHost}/api/countries/HT/`,
    code: "HT",
    iso_3166_1_a3: "HTI",
    printable_name: "Haiti",
    iso_3166_1_numeric: "332",
  },
  {
    url: `https://${constants.apiHost}/api/countries/HM/`,
    code: "HM",
    iso_3166_1_a3: "HMD",
    printable_name: "Heard Island and McDonald Islands",
    iso_3166_1_numeric: "334",
  },
  {
    url: `https://${constants.apiHost}/api/countries/VA/`,
    code: "VA",
    iso_3166_1_a3: "VAT",
    printable_name: "Holy See",
    iso_3166_1_numeric: "336",
  },
  {
    url: `https://${constants.apiHost}/api/countries/HN/`,
    code: "HN",
    iso_3166_1_a3: "HND",
    printable_name: "Honduras",
    iso_3166_1_numeric: "340",
  },
  {
    url: `https://${constants.apiHost}/api/countries/HK/`,
    code: "HK",
    iso_3166_1_a3: "HKG",
    printable_name: "Hong Kong",
    iso_3166_1_numeric: "344",
  },
  {
    url: `https://${constants.apiHost}/api/countries/HU/`,
    code: "HU",
    iso_3166_1_a3: "HUN",
    printable_name: "Hungary",
    iso_3166_1_numeric: "348",
  },
  {
    url: `https://${constants.apiHost}/api/countries/IS/`,
    code: "IS",
    iso_3166_1_a3: "ISL",
    printable_name: "Iceland",
    iso_3166_1_numeric: "352",
  },
  {
    url: `https://${constants.apiHost}/api/countries/IN/`,
    code: "IN",
    iso_3166_1_a3: "IND",
    printable_name: "India",
    iso_3166_1_numeric: "356",
  },
  {
    url: `https://${constants.apiHost}/api/countries/ID/`,
    code: "ID",
    iso_3166_1_a3: "IDN",
    printable_name: "Indonesia",
    iso_3166_1_numeric: "360",
  },
  {
    url: `https://${constants.apiHost}/api/countries/IR/`,
    code: "IR",
    iso_3166_1_a3: "IRN",
    printable_name: "Iran",
    iso_3166_1_numeric: "364",
  },
  {
    url: `https://${constants.apiHost}/api/countries/IQ/`,
    code: "IQ",
    iso_3166_1_a3: "IRQ",
    printable_name: "Iraq",
    iso_3166_1_numeric: "368",
  },
  {
    url: `https://${constants.apiHost}/api/countries/IE/`,
    code: "IE",
    iso_3166_1_a3: "IRL",
    printable_name: "Ireland",
    iso_3166_1_numeric: "372",
  },
  {
    url: `https://${constants.apiHost}/api/countries/IM/`,
    code: "IM",
    iso_3166_1_a3: "IMN",
    printable_name: "Isle of Man",
    iso_3166_1_numeric: "833",
  },
  {
    url: `https://${constants.apiHost}/api/countries/IL/`,
    code: "IL",
    iso_3166_1_a3: "ISR",
    printable_name: "Israel",
    iso_3166_1_numeric: "376",
  },
  {
    url: `https://${constants.apiHost}/api/countries/IT/`,
    code: "IT",
    iso_3166_1_a3: "ITA",
    printable_name: "Italy",
    iso_3166_1_numeric: "380",
  },
  {
    url: `https://${constants.apiHost}/api/countries/JM/`,
    code: "JM",
    iso_3166_1_a3: "JAM",
    printable_name: "Jamaica",
    iso_3166_1_numeric: "388",
  },
  {
    url: `https://${constants.apiHost}/api/countries/JP/`,
    code: "JP",
    iso_3166_1_a3: "JPN",
    printable_name: "Japan",
    iso_3166_1_numeric: "392",
  },
  {
    url: `https://${constants.apiHost}/api/countries/JE/`,
    code: "JE",
    iso_3166_1_a3: "JEY",
    printable_name: "Jersey",
    iso_3166_1_numeric: "832",
  },
  {
    url: `https://${constants.apiHost}/api/countries/JO/`,
    code: "JO",
    iso_3166_1_a3: "JOR",
    printable_name: "Jordan",
    iso_3166_1_numeric: "400",
  },
  {
    url: `https://${constants.apiHost}/api/countries/KZ/`,
    code: "KZ",
    iso_3166_1_a3: "KAZ",
    printable_name: "Kazakhstan",
    iso_3166_1_numeric: "398",
  },
  {
    url: `https://${constants.apiHost}/api/countries/KE/`,
    code: "KE",
    iso_3166_1_a3: "KEN",
    printable_name: "Kenya",
    iso_3166_1_numeric: "404",
  },
  {
    url: `https://${constants.apiHost}/api/countries/KI/`,
    code: "KI",
    iso_3166_1_a3: "KIR",
    printable_name: "Kiribati",
    iso_3166_1_numeric: "296",
  },
  {
    url: `https://${constants.apiHost}/api/countries/KP/`,
    code: "KP",
    iso_3166_1_a3: "PRK",
    printable_name: "People's Republic of Korea",
    iso_3166_1_numeric: "408",
  },
  {
    url: `https://${constants.apiHost}/api/countries/KR/`,
    code: "KR",
    iso_3166_1_a3: "KOR",
    printable_name: "South Korea",
    iso_3166_1_numeric: "410",
  },
  {
    url: `https://${constants.apiHost}/api/countries/KW/`,
    code: "KW",
    iso_3166_1_a3: "KWT",
    printable_name: "Kuwait",
    iso_3166_1_numeric: "414",
  },
  {
    url: `https://${constants.apiHost}/api/countries/KG/`,
    code: "KG",
    iso_3166_1_a3: "KGZ",
    printable_name: "Kyrgyzstan",
    iso_3166_1_numeric: "417",
  },
  {
    url: `https://${constants.apiHost}/api/countries/LA/`,
    code: "LA",
    iso_3166_1_a3: "LAO",
    printable_name: "Laos",
    iso_3166_1_numeric: "418",
  },
  {
    url: `https://${constants.apiHost}/api/countries/LV/`,
    code: "LV",
    iso_3166_1_a3: "LVA",
    printable_name: "Latvia",
    iso_3166_1_numeric: "428",
  },
  {
    url: `https://${constants.apiHost}/api/countries/LB/`,
    code: "LB",
    iso_3166_1_a3: "LBN",
    printable_name: "Lebanon",
    iso_3166_1_numeric: "422",
  },
  {
    url: `https://${constants.apiHost}/api/countries/LS/`,
    code: "LS",
    iso_3166_1_a3: "LSO",
    printable_name: "Lesotho",
    iso_3166_1_numeric: "426",
  },
  {
    url: `https://${constants.apiHost}/api/countries/LR/`,
    code: "LR",
    iso_3166_1_a3: "LBR",
    printable_name: "Liberia",
    iso_3166_1_numeric: "430",
  },
  {
    url: `https://${constants.apiHost}/api/countries/LY/`,
    code: "LY",
    iso_3166_1_a3: "LBY",
    printable_name: "Libya",
    iso_3166_1_numeric: "434",
  },
  {
    url: `https://${constants.apiHost}/api/countries/LI/`,
    code: "LI",
    iso_3166_1_a3: "LIE",
    printable_name: "Liechtenstein",
    iso_3166_1_numeric: "438",
  },
  {
    url: `https://${constants.apiHost}/api/countries/LT/`,
    code: "LT",
    iso_3166_1_a3: "LTU",
    printable_name: "Lithuania",
    iso_3166_1_numeric: "440",
  },
  {
    url: `https://${constants.apiHost}/api/countries/LU/`,
    code: "LU",
    iso_3166_1_a3: "LUX",
    printable_name: "Luxembourg",
    iso_3166_1_numeric: "442",
  },
  {
    url: `https://${constants.apiHost}/api/countries/MO/`,
    code: "MO",
    iso_3166_1_a3: "MAC",
    printable_name: "Macao",
    iso_3166_1_numeric: "446",
  },
  {
    url: `https://${constants.apiHost}/api/countries/MG/`,
    code: "MG",
    iso_3166_1_a3: "MDG",
    printable_name: "Madagascar",
    iso_3166_1_numeric: "450",
  },
  {
    url: `https://${constants.apiHost}/api/countries/MW/`,
    code: "MW",
    iso_3166_1_a3: "MWI",
    printable_name: "Malawi",
    iso_3166_1_numeric: "454",
  },
  {
    url: `https://${constants.apiHost}/api/countries/MY/`,
    code: "MY",
    iso_3166_1_a3: "MYS",
    printable_name: "Malaysia",
    iso_3166_1_numeric: "458",
  },
  {
    url: `https://${constants.apiHost}/api/countries/MV/`,
    code: "MV",
    iso_3166_1_a3: "MDV",
    printable_name: "Maldives",
    iso_3166_1_numeric: "462",
  },
  {
    url: `https://${constants.apiHost}/api/countries/ML/`,
    code: "ML",
    iso_3166_1_a3: "MLI",
    printable_name: "Mali",
    iso_3166_1_numeric: "466",
  },
  {
    url: `https://${constants.apiHost}/api/countries/MT/`,
    code: "MT",
    iso_3166_1_a3: "MLT",
    printable_name: "Malta",
    iso_3166_1_numeric: "470",
  },
  {
    url: `https://${constants.apiHost}/api/countries/MH/`,
    code: "MH",
    iso_3166_1_a3: "MHL",
    printable_name: "Marshall Islands",
    iso_3166_1_numeric: "584",
  },
  {
    url: `https://${constants.apiHost}/api/countries/MQ/`,
    code: "MQ",
    iso_3166_1_a3: "MTQ",
    printable_name: "Martinique",
    iso_3166_1_numeric: "474",
  },
  {
    url: `https://${constants.apiHost}/api/countries/MR/`,
    code: "MR",
    iso_3166_1_a3: "MRT",
    printable_name: "Mauritania",
    iso_3166_1_numeric: "478",
  },
  {
    url: `https://${constants.apiHost}/api/countries/MU/`,
    code: "MU",
    iso_3166_1_a3: "MUS",
    printable_name: "Mauritius",
    iso_3166_1_numeric: "480",
  },
  {
    url: `https://${constants.apiHost}/api/countries/YT/`,
    code: "YT",
    iso_3166_1_a3: "MYT",
    printable_name: "Mayotte",
    iso_3166_1_numeric: "175",
  },
  {
    url: `https://${constants.apiHost}/api/countries/MX/`,
    code: "MX",
    iso_3166_1_a3: "MEX",
    printable_name: "Mexico",
    iso_3166_1_numeric: "484",
  },
  {
    url: `https://${constants.apiHost}/api/countries/FM/`,
    code: "FM",
    iso_3166_1_a3: "FSM",
    printable_name: "Federated States of Micronesia",
    iso_3166_1_numeric: "583",
  },
  {
    url: `https://${constants.apiHost}/api/countries/MD/`,
    code: "MD",
    iso_3166_1_a3: "MDA",
    printable_name: "Moldova",
    iso_3166_1_numeric: "498",
  },
  {
    url: `https://${constants.apiHost}/api/countries/MC/`,
    code: "MC",
    iso_3166_1_a3: "MCO",
    printable_name: "Monaco",
    iso_3166_1_numeric: "492",
  },
  {
    url: `https://${constants.apiHost}/api/countries/MN/`,
    code: "MN",
    iso_3166_1_a3: "MNG",
    printable_name: "Mongolia",
    iso_3166_1_numeric: "496",
  },
  {
    url: `https://${constants.apiHost}/api/countries/ME/`,
    code: "ME",
    iso_3166_1_a3: "MNE",
    printable_name: "Montenegro",
    iso_3166_1_numeric: "499",
  },
  {
    url: `https://${constants.apiHost}/api/countries/MS/`,
    code: "MS",
    iso_3166_1_a3: "MSR",
    printable_name: "Montserrat",
    iso_3166_1_numeric: "500",
  },
  {
    url: `https://${constants.apiHost}/api/countries/MA/`,
    code: "MA",
    iso_3166_1_a3: "MAR",
    printable_name: "Morocco",
    iso_3166_1_numeric: "504",
  },
  {
    url: `https://${constants.apiHost}/api/countries/MZ/`,
    code: "MZ",
    iso_3166_1_a3: "MOZ",
    printable_name: "Mozambique",
    iso_3166_1_numeric: "508",
  },
  {
    url: `https://${constants.apiHost}/api/countries/MM/`,
    code: "MM",
    iso_3166_1_a3: "MMR",
    printable_name: "Myanmar",
    iso_3166_1_numeric: "104",
  },
  {
    url: `https://${constants.apiHost}/api/countries/NA/`,
    code: "NA",
    iso_3166_1_a3: "NAM",
    printable_name: "Namibia",
    iso_3166_1_numeric: "516",
  },
  {
    url: `https://${constants.apiHost}/api/countries/NR/`,
    code: "NR",
    iso_3166_1_a3: "NRU",
    printable_name: "Nauru",
    iso_3166_1_numeric: "520",
  },
  {
    url: `https://${constants.apiHost}/api/countries/NP/`,
    code: "NP",
    iso_3166_1_a3: "NPL",
    printable_name: "Nepal",
    iso_3166_1_numeric: "524",
  },
  {
    url: `https://${constants.apiHost}/api/countries/NL/`,
    code: "NL",
    iso_3166_1_a3: "NLD",
    printable_name: "Netherlands",
    iso_3166_1_numeric: "528",
  },
  {
    url: `https://${constants.apiHost}/api/countries/NC/`,
    code: "NC",
    iso_3166_1_a3: "NCL",
    printable_name: "New Caledonia",
    iso_3166_1_numeric: "540",
  },
  {
    url: `https://${constants.apiHost}/api/countries/NZ/`,
    code: "NZ",
    iso_3166_1_a3: "NZL",
    printable_name: "New Zealand",
    iso_3166_1_numeric: "554",
  },
  {
    url: `https://${constants.apiHost}/api/countries/NI/`,
    code: "NI",
    iso_3166_1_a3: "NIC",
    printable_name: "Nicaragua",
    iso_3166_1_numeric: "558",
  },
  {
    url: `https://${constants.apiHost}/api/countries/NE/`,
    code: "NE",
    iso_3166_1_a3: "NER",
    printable_name: "Niger",
    iso_3166_1_numeric: "562",
  },
  {
    url: `https://${constants.apiHost}/api/countries/NG/`,
    code: "NG",
    iso_3166_1_a3: "NGA",
    printable_name: "Nigeria",
    iso_3166_1_numeric: "566",
  },
  {
    url: `https://${constants.apiHost}/api/countries/NU/`,
    code: "NU",
    iso_3166_1_a3: "NIU",
    printable_name: "Niue",
    iso_3166_1_numeric: "570",
  },
  {
    url: `https://${constants.apiHost}/api/countries/NF/`,
    code: "NF",
    iso_3166_1_a3: "NFK",
    printable_name: "Norfolk Island",
    iso_3166_1_numeric: "574",
  },
  {
    url: `https://${constants.apiHost}/api/countries/MP/`,
    code: "MP",
    iso_3166_1_a3: "MNP",
    printable_name: "Northern Mariana Islands",
    iso_3166_1_numeric: "580",
  },
  {
    url: `https://${constants.apiHost}/api/countries/NO/`,
    code: "NO",
    iso_3166_1_a3: "NOR",
    printable_name: "Norway",
    iso_3166_1_numeric: "578",
  },
  {
    url: `https://${constants.apiHost}/api/countries/OM/`,
    code: "OM",
    iso_3166_1_a3: "OMN",
    printable_name: "Oman",
    iso_3166_1_numeric: "512",
  },
  {
    url: `https://${constants.apiHost}/api/countries/PK/`,
    code: "PK",
    iso_3166_1_a3: "PAK",
    printable_name: "Pakistan",
    iso_3166_1_numeric: "586",
  },
  {
    url: `https://${constants.apiHost}/api/countries/PW/`,
    code: "PW",
    iso_3166_1_a3: "PLW",
    printable_name: "Palau",
    iso_3166_1_numeric: "585",
  },
  {
    url: `https://${constants.apiHost}/api/countries/PS/`,
    code: "PS",
    iso_3166_1_a3: "PSE",
    printable_name: "Palestine",
    iso_3166_1_numeric: "275",
  },
  {
    url: `https://${constants.apiHost}/api/countries/PA/`,
    code: "PA",
    iso_3166_1_a3: "PAN",
    printable_name: "Panama",
    iso_3166_1_numeric: "591",
  },
  {
    url: `https://${constants.apiHost}/api/countries/PG/`,
    code: "PG",
    iso_3166_1_a3: "PNG",
    printable_name: "Papua New Guinea",
    iso_3166_1_numeric: "598",
  },
  {
    url: `https://${constants.apiHost}/api/countries/PY/`,
    code: "PY",
    iso_3166_1_a3: "PRY",
    printable_name: "Paraguay",
    iso_3166_1_numeric: "600",
  },
  {
    url: `https://${constants.apiHost}/api/countries/PE/`,
    code: "PE",
    iso_3166_1_a3: "PER",
    printable_name: "Peru",
    iso_3166_1_numeric: "604",
  },
  {
    url: `https://${constants.apiHost}/api/countries/PH/`,
    code: "PH",
    iso_3166_1_a3: "PHL",
    printable_name: "Philippines",
    iso_3166_1_numeric: "608",
  },
  {
    url: `https://${constants.apiHost}/api/countries/PN/`,
    code: "PN",
    iso_3166_1_a3: "PCN",
    printable_name: "Pitcairn",
    iso_3166_1_numeric: "612",
  },
  {
    url: `https://${constants.apiHost}/api/countries/PL/`,
    code: "PL",
    iso_3166_1_a3: "POL",
    printable_name: "Poland",
    iso_3166_1_numeric: "616",
  },
  {
    url: `https://${constants.apiHost}/api/countries/PT/`,
    code: "PT",
    iso_3166_1_a3: "PRT",
    printable_name: "Portugal",
    iso_3166_1_numeric: "620",
  },
  {
    url: `https://${constants.apiHost}/api/countries/PR/`,
    code: "PR",
    iso_3166_1_a3: "PRI",
    printable_name: "Puerto Rico",
    iso_3166_1_numeric: "630",
  },
  {
    url: `https://${constants.apiHost}/api/countries/QA/`,
    code: "QA",
    iso_3166_1_a3: "QAT",
    printable_name: "Qatar",
    iso_3166_1_numeric: "634",
  },
  {
    url: `https://${constants.apiHost}/api/countries/MK/`,
    code: "MK",
    iso_3166_1_a3: "MKD",
    printable_name: "Republic of North Macedonia",
    iso_3166_1_numeric: "807",
  },
  {
    url: `https://${constants.apiHost}/api/countries/RO/`,
    code: "RO",
    iso_3166_1_a3: "ROU",
    printable_name: "Romania",
    iso_3166_1_numeric: "642",
  },
  {
    url: `https://${constants.apiHost}/api/countries/RU/`,
    code: "RU",
    iso_3166_1_a3: "RUS",
    printable_name: "Russian Federation",
    iso_3166_1_numeric: "643",
  },
  {
    url: `https://${constants.apiHost}/api/countries/RW/`,
    code: "RW",
    iso_3166_1_a3: "RWA",
    printable_name: "Rwanda",
    iso_3166_1_numeric: "646",
  },
  {
    url: `https://${constants.apiHost}/api/countries/RE/`,
    code: "RE",
    iso_3166_1_a3: "REU",
    printable_name: "Réunion",
    iso_3166_1_numeric: "638",
  },
  {
    url: `https://${constants.apiHost}/api/countries/BL/`,
    code: "BL",
    iso_3166_1_a3: "BLM",
    printable_name: "Saint Barthélemy",
    iso_3166_1_numeric: "652",
  },
  {
    url: `https://${constants.apiHost}/api/countries/SH/`,
    code: "SH",
    iso_3166_1_a3: "SHN",
    printable_name: "Saint Helena, Ascension and Tristan da Cunha",
    iso_3166_1_numeric: "654",
  },
  {
    url: `https://${constants.apiHost}/api/countries/KN/`,
    code: "KN",
    iso_3166_1_a3: "KNA",
    printable_name: "Saint Kitts and Nevis",
    iso_3166_1_numeric: "659",
  },
  {
    url: `https://${constants.apiHost}/api/countries/LC/`,
    code: "LC",
    iso_3166_1_a3: "LCA",
    printable_name: "Saint Lucia",
    iso_3166_1_numeric: "662",
  },
  {
    url: `https://${constants.apiHost}/api/countries/MF/`,
    code: "MF",
    iso_3166_1_a3: "MAF",
    printable_name: "Saint Martin",
    iso_3166_1_numeric: "663",
  },
  {
    url: `https://${constants.apiHost}/api/countries/PM/`,
    code: "PM",
    iso_3166_1_a3: "SPM",
    printable_name: "Saint Pierre and Miquelon",
    iso_3166_1_numeric: "666",
  },
  {
    url: `https://${constants.apiHost}/api/countries/VC/`,
    code: "VC",
    iso_3166_1_a3: "VCT",
    printable_name: "Saint Vincent and the Grenadines",
    iso_3166_1_numeric: "670",
  },
  {
    url: `https://${constants.apiHost}/api/countries/WS/`,
    code: "WS",
    iso_3166_1_a3: "WSM",
    printable_name: "Samoa",
    iso_3166_1_numeric: "882",
  },
  {
    url: `https://${constants.apiHost}/api/countries/SM/`,
    code: "SM",
    iso_3166_1_a3: "SMR",
    printable_name: "San Marino",
    iso_3166_1_numeric: "674",
  },
  {
    url: `https://${constants.apiHost}/api/countries/ST/`,
    code: "ST",
    iso_3166_1_a3: "STP",
    printable_name: "Sao Tome and Principe",
    iso_3166_1_numeric: "678",
  },
  {
    url: `https://${constants.apiHost}/api/countries/SA/`,
    code: "SA",
    iso_3166_1_a3: "SAU",
    printable_name: "Saudi Arabia",
    iso_3166_1_numeric: "682",
  },
  {
    url: `https://${constants.apiHost}/api/countries/SN/`,
    code: "SN",
    iso_3166_1_a3: "SEN",
    printable_name: "Senegal",
    iso_3166_1_numeric: "686",
  },
  {
    url: `https://${constants.apiHost}/api/countries/RS/`,
    code: "RS",
    iso_3166_1_a3: "SRB",
    printable_name: "Serbia",
    iso_3166_1_numeric: "688",
  },
  {
    url: `https://${constants.apiHost}/api/countries/SC/`,
    code: "SC",
    iso_3166_1_a3: "SYC",
    printable_name: "Seychelles",
    iso_3166_1_numeric: "690",
  },
  {
    url: `https://${constants.apiHost}/api/countries/SL/`,
    code: "SL",
    iso_3166_1_a3: "SLE",
    printable_name: "Sierra Leone",
    iso_3166_1_numeric: "694",
  },
  {
    url: `https://${constants.apiHost}/api/countries/SG/`,
    code: "SG",
    iso_3166_1_a3: "SGP",
    printable_name: "Singapore",
    iso_3166_1_numeric: "702",
  },
  {
    url: `https://${constants.apiHost}/api/countries/SX/`,
    code: "SX",
    iso_3166_1_a3: "SXM",
    printable_name: "Sint Maarten",
    iso_3166_1_numeric: "534",
  },
  {
    url: `https://${constants.apiHost}/api/countries/SK/`,
    code: "SK",
    iso_3166_1_a3: "SVK",
    printable_name: "Slovakia",
    iso_3166_1_numeric: "703",
  },
  {
    url: `https://${constants.apiHost}/api/countries/SI/`,
    code: "SI",
    iso_3166_1_a3: "SVN",
    printable_name: "Slovenia",
    iso_3166_1_numeric: "705",
  },
  {
    url: `https://${constants.apiHost}/api/countries/SB/`,
    code: "SB",
    iso_3166_1_a3: "SLB",
    printable_name: "Solomon Islands",
    iso_3166_1_numeric: "090",
  },
  {
    url: `https://${constants.apiHost}/api/countries/SO/`,
    code: "SO",
    iso_3166_1_a3: "SOM",
    printable_name: "Somalia",
    iso_3166_1_numeric: "706",
  },
  {
    url: `https://${constants.apiHost}/api/countries/ZA/`,
    code: "ZA",
    iso_3166_1_a3: "ZAF",
    printable_name: "South Africa",
    iso_3166_1_numeric: "710",
  },
  {
    url: `https://${constants.apiHost}/api/countries/GS/`,
    code: "GS",
    iso_3166_1_a3: "SGS",
    printable_name: "South Georgia and the South Sandwich Islands",
    iso_3166_1_numeric: "239",
  },
  {
    url: `https://${constants.apiHost}/api/countries/SS/`,
    code: "SS",
    iso_3166_1_a3: "SSD",
    printable_name: "South Sudan",
    iso_3166_1_numeric: "728",
  },
  {
    url: `https://${constants.apiHost}/api/countries/ES/`,
    code: "ES",
    iso_3166_1_a3: "ESP",
    printable_name: "Spain",
    iso_3166_1_numeric: "724",
  },
  {
    url: `https://${constants.apiHost}/api/countries/LK/`,
    code: "LK",
    iso_3166_1_a3: "LKA",
    printable_name: "Sri Lanka",
    iso_3166_1_numeric: "144",
  },
  {
    url: `https://${constants.apiHost}/api/countries/SD/`,
    code: "SD",
    iso_3166_1_a3: "SDN",
    printable_name: "Sudan",
    iso_3166_1_numeric: "729",
  },
  {
    url: `https://${constants.apiHost}/api/countries/SR/`,
    code: "SR",
    iso_3166_1_a3: "SUR",
    printable_name: "Suriname",
    iso_3166_1_numeric: "740",
  },
  {
    url: `https://${constants.apiHost}/api/countries/SJ/`,
    code: "SJ",
    iso_3166_1_a3: "SJM",
    printable_name: "Svalbard and Jan Mayen",
    iso_3166_1_numeric: "744",
  },
  {
    url: `https://${constants.apiHost}/api/countries/SE/`,
    code: "SE",
    iso_3166_1_a3: "SWE",
    printable_name: "Sweden",
    iso_3166_1_numeric: "752",
  },
  {
    url: `https://${constants.apiHost}/api/countries/CH/`,
    code: "CH",
    iso_3166_1_a3: "CHE",
    printable_name: "Switzerland",
    iso_3166_1_numeric: "756",
  },
  {
    url: `https://${constants.apiHost}/api/countries/SY/`,
    code: "SY",
    iso_3166_1_a3: "SYR",
    printable_name: "Syrian Arab Republic",
    iso_3166_1_numeric: "760",
  },
  {
    url: `https://${constants.apiHost}/api/countries/TW/`,
    code: "TW",
    iso_3166_1_a3: "TWN",
    printable_name: "Taiwan",
    iso_3166_1_numeric: "158",
  },
  {
    url: `https://${constants.apiHost}/api/countries/TJ/`,
    code: "TJ",
    iso_3166_1_a3: "TJK",
    printable_name: "Tajikistan",
    iso_3166_1_numeric: "762",
  },
  {
    url: `https://${constants.apiHost}/api/countries/TZ/`,
    code: "TZ",
    iso_3166_1_a3: "TZA",
    printable_name: "Tanzania",
    iso_3166_1_numeric: "834",
  },
  {
    url: `https://${constants.apiHost}/api/countries/TH/`,
    code: "TH",
    iso_3166_1_a3: "THA",
    printable_name: "Thailand",
    iso_3166_1_numeric: "764",
  },
  {
    url: `https://${constants.apiHost}/api/countries/TL/`,
    code: "TL",
    iso_3166_1_a3: "TLS",
    printable_name: "Timor-Leste",
    iso_3166_1_numeric: "626",
  },
  {
    url: `https://${constants.apiHost}/api/countries/TG/`,
    code: "TG",
    iso_3166_1_a3: "TGO",
    printable_name: "Togo",
    iso_3166_1_numeric: "768",
  },
  {
    url: `https://${constants.apiHost}/api/countries/TK/`,
    code: "TK",
    iso_3166_1_a3: "TKL",
    printable_name: "Tokelau",
    iso_3166_1_numeric: "772",
  },
  {
    url: `https://${constants.apiHost}/api/countries/TO/`,
    code: "TO",
    iso_3166_1_a3: "TON",
    printable_name: "Tonga",
    iso_3166_1_numeric: "776",
  },
  {
    url: `https://${constants.apiHost}/api/countries/TT/`,
    code: "TT",
    iso_3166_1_a3: "TTO",
    printable_name: "Trinidad and Tobago",
    iso_3166_1_numeric: "780",
  },
  {
    url: `https://${constants.apiHost}/api/countries/TN/`,
    code: "TN",
    iso_3166_1_a3: "TUN",
    printable_name: "Tunisia",
    iso_3166_1_numeric: "788",
  },
  {
    url: `https://${constants.apiHost}/api/countries/TR/`,
    code: "TR",
    iso_3166_1_a3: "TUR",
    printable_name: "Turkey",
    iso_3166_1_numeric: "792",
  },
  {
    url: `https://${constants.apiHost}/api/countries/TM/`,
    code: "TM",
    iso_3166_1_a3: "TKM",
    printable_name: "Turkmenistan",
    iso_3166_1_numeric: "795",
  },
  {
    url: `https://${constants.apiHost}/api/countries/TC/`,
    code: "TC",
    iso_3166_1_a3: "TCA",
    printable_name: "Turks and Caicos Islands",
    iso_3166_1_numeric: "796",
  },
  {
    url: `https://${constants.apiHost}/api/countries/TV/`,
    code: "TV",
    iso_3166_1_a3: "TUV",
    printable_name: "Tuvalu",
    iso_3166_1_numeric: "798",
  },
  {
    url: `https://${constants.apiHost}/api/countries/UG/`,
    code: "UG",
    iso_3166_1_a3: "UGA",
    printable_name: "Uganda",
    iso_3166_1_numeric: "800",
  },
  {
    url: `https://${constants.apiHost}/api/countries/UA/`,
    code: "UA",
    iso_3166_1_a3: "UKR",
    printable_name: "Ukraine",
    iso_3166_1_numeric: "804",
  },
  {
    url: `https://${constants.apiHost}/api/countries/AE/`,
    code: "AE",
    iso_3166_1_a3: "ARE",
    printable_name: "United Arab Emirates",
    iso_3166_1_numeric: "784",
  },
  {
    url: `https://${constants.apiHost}/api/countries/GB/`,
    code: "GB",
    iso_3166_1_a3: "GBR",
    printable_name: "United Kingdom",
    iso_3166_1_numeric: "826",
  },
  {
    url: `https://${constants.apiHost}/api/countries/UM/`,
    code: "UM",
    iso_3166_1_a3: "UMI",
    printable_name: "United States Minor Outlying Islands",
    iso_3166_1_numeric: "581",
  },
  {
    url: `https://${constants.apiHost}/api/countries/US/`,
    code: "US",
    iso_3166_1_a3: "USA",
    printable_name: "United States",
    iso_3166_1_numeric: "840",
  },
  {
    url: `https://${constants.apiHost}/api/countries/UY/`,
    code: "UY",
    iso_3166_1_a3: "URY",
    printable_name: "Uruguay",
    iso_3166_1_numeric: "858",
  },
  {
    url: `https://${constants.apiHost}/api/countries/UZ/`,
    code: "UZ",
    iso_3166_1_a3: "UZB",
    printable_name: "Uzbekistan",
    iso_3166_1_numeric: "860",
  },
  {
    url: `https://${constants.apiHost}/api/countries/VU/`,
    code: "VU",
    iso_3166_1_a3: "VUT",
    printable_name: "Vanuatu",
    iso_3166_1_numeric: "548",
  },
  {
    url: `https://${constants.apiHost}/api/countries/VE/`,
    code: "VE",
    iso_3166_1_a3: "VEN",
    printable_name: "Venezuela",
    iso_3166_1_numeric: "862",
  },
  {
    url: `https://${constants.apiHost}/api/countries/VN/`,
    code: "VN",
    iso_3166_1_a3: "VNM",
    printable_name: "Viet Nam",
    iso_3166_1_numeric: "704",
  },
  {
    url: `https://${constants.apiHost}/api/countries/VG/`,
    code: "VG",
    iso_3166_1_a3: "VGB",
    printable_name: "Virgin Islands (British)",
    iso_3166_1_numeric: "092",
  },
  {
    url: `https://${constants.apiHost}/api/countries/VI/`,
    code: "VI",
    iso_3166_1_a3: "VIR",
    printable_name: "Virgin Islands (U.S.)",
    iso_3166_1_numeric: "850",
  },
  {
    url: `https://${constants.apiHost}/api/countries/WF/`,
    code: "WF",
    iso_3166_1_a3: "WLF",
    printable_name: "Wallis and Futuna",
    iso_3166_1_numeric: "876",
  },
  {
    url: `https://${constants.apiHost}/api/countries/EH/`,
    code: "EH",
    iso_3166_1_a3: "ESH",
    printable_name: "Western Sahara",
    iso_3166_1_numeric: "732",
  },
  {
    url: `https://${constants.apiHost}/api/countries/YE/`,
    code: "YE",
    iso_3166_1_a3: "YEM",
    printable_name: "Yemen",
    iso_3166_1_numeric: "887",
  },
  {
    url: `https://${constants.apiHost}/api/countries/ZM/`,
    code: "ZM",
    iso_3166_1_a3: "ZMB",
    printable_name: "Zambia",
    iso_3166_1_numeric: "894",
  },
  {
    url: `https://${constants.apiHost}/api/countries/ZW/`,
    code: "ZW",
    iso_3166_1_a3: "ZWE",
    printable_name: "Zimbabwe",
    iso_3166_1_numeric: "716",
  },
  {
    url: `https://${constants.apiHost}/api/countries/AX/`,
    code: "AX",
    iso_3166_1_a3: "ALA",
    printable_name: "Åland Islands",
    iso_3166_1_numeric: "248",
  },
];

export const defaultCountry = countriesList.find(
  (country) => country.iso_3166_1_a3 === (constants.id === ID_ICSEARCH ? "RUS" : "CHE"),
);

export default "const";
